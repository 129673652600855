import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';

const UseCookie = () => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const setCustomCookie = (name, value, options = {}) => {
    try {
      const expirationTime = new Date();
      expirationTime.setTime(expirationTime.getTime() + (12 * 60 * 60 * 1000)); // 12 hours from now

      const finalOptions = {
        expires: expirationTime,
        path: '/',
        // domain: 'devweb01.nativenest.in',
        domain: 'nativenest.in',
        secure: process.env.NODE_ENV == 'production',
        sameSite: 'Strict',
        ...options,
      };

      // setCookie(name, value, finalOptions);  //production
      // setCookie(name, value); //local
      setCookie(name, value, (process.env.NODE_ENV == 'development'?{ path: '/',}:finalOptions));
    } catch (error) {
      //console.log("Error setting cookie:", error);
      toast.error("Error setting cookie");
    }
  };

  const getCustomCookie = (name) => {
    try {
      //console.log("🚀 ~ getCustomCookie ~ cookies:", cookies)
      return cookies[name];
    } catch (error) {
      //console.log("Error getting cookie:", error);
      toast.error("Error getting cookie");
      return null;
    }
  };

  const removeCustomCookie = (name) => {
    try {
      // removeCookie(name, { path: '/', domain: 'devweb01.nativenest.in' });//production
      // removeCookie(name, { path: '/', domain: 'nativenest.in' });//production
      // removeCookie(name); //local
      removeCookie(name, (process.env.NODE_ENV == 'development'?{ path: '/',}:{ path: '/', domain: 'nativenest.in' }));//production
      //console.log("Cookie removed:", name);
    } catch (error) {
      //console.log("Error removing cookie:", error);
      toast.error("Error removing cookie 2 "+error);
    }
  };

  const clearAllCookies = async () => {
    try {
      for (let cookieName in cookies) {
        // removeCookie(cookieName, { path: '/', domain: 'devweb01.nativenest.in' }); //production
        // removeCookie(cookieName, { path: '/', domain: 'nativenest.in' }); //production
        removeCookie(cookieName, (process.env.NODE_ENV == 'development'?{ path: '/',}:{ path: '/', domain: 'nativenest.in' })); //production
      }
      // clearAllCookiesByExpiration();
      //console.log("All cookies cleared");
    } catch (error) {
      //console.log("Error clearing all cookies:", error);
      toast.error("Error clearing all cookies 1 "+error);
    }
  };

  const clearAllCookiesByExpiration = () => {
    try {
      const pathList = ['/'];
      const domain = 'nativenest.in';
      const cookiesList = document.cookie.split('; ');

      cookiesList.forEach(cookie => {
        const cookieName = cookie.split('=')[0];

        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${domain};`;

        pathList.forEach(path => {
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}; domain=${domain};`;
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}`;
        });
      });
      //console.log("Cookies cleared by expiration");
    } catch (error) {
      //console.log("Error clearing cookies by expiration:", error);
      toast.error("Error clearing cookies by expiration");
    }
  };

  return {
    setCustomCookie,
    setCookie,
    getCustomCookie,
    removeCustomCookie,
    clearAllCookies,
    cookies,
  };
};

export default UseCookie;



// // useCookie.js
// import { useCookies } from 'react-cookie';
// import { toast } from 'react-toastify';

// const UseCookie = () => {
//   const [cookies, setCookie, removeCookie] = useCookies();

//   const setCustomCookie = async (name, value, options = {}) => {
//     try {
//       // Calculate expiration time (12 hours from now)
//       const expirationTime = new Date();
//       expirationTime.setTime(expirationTime.getTime() + (12 * 60 * 60 * 1000)); // 12 hours
  
//       // Default options including expiration and domain
//       const finalOptions = {
//         ...options,
//         expires: expirationTime,
//         path: '/',
//         domain: 'nativenest.in',
//         secure: true,
//         sameSite: 'Strict',
//       };
  
//       await setCookie(name, value, finalOptions);
//       // await setCookie(name, value);
//     } catch (error) {
//       //console.log("🚀 ~ setCustomCookie ~ error:", error);
//     }
//   };
  

//   const getCustomCookie = async(name) => {
//     let value = await cookies[name];
//     return value;
//   };

//   const removeCustomCookie = async (name) => {
//     await removeCookie(name);
//   };

//   const clearAllCookies = async () => {
//     for (let cookieName in cookies) {
//       await removeCookie(cookieName);
//     }
//     try {
//       await cookies.removeAll(); // Remove all cookies
//     } catch (error) {
//       toast.error(error); // Display error message
//     }
//     try{
//       clearAllCookiesByExpiration()
//     }
//     catch(error){
//       toast.error(error)
//     }
//   };

//   const clearAllCookiesByExpiration = async () => {
//     const cookies = document.cookie.split('; ');
//     const pathList = ['/']; // Adjust paths as needed
//     const domain = 'nativenest.in'; // Get the base domain
  
//     for (let cookie of cookies) {
//       const cookieName = cookie.split('=')[0];
  
//       // Set the cookie expiry date to the past to delete it
//       document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${domain};`;
  
//       // Delete cookies for specific paths (optional)
//       if (pathList.length > 0) {
//         for (let path of pathList) {
//           document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}; domain=${domain};`;
//           document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}`;
//         }
//       }
//     }
  
//     // Consider a callback or promise to signal completion (optional)
//     // if (onComplete) {
//     //   onComplete();
//     // }
//     // return new Promise((resolve) => resolve());
//   };  

//   return {
//     setCustomCookie,
//     getCustomCookie,
//     removeCustomCookie,
//     clearAllCookies,
//     cookies, setCookie, removeCookie
//   };
// };

// export default UseCookie;


// // loginInfo