import React, {useState,useEffect, useCallback, useLayoutEffect, useRef} from 'react'
import NavBar from './screens/NavBar/NavBar'
import {
  BrowserRouter,
  Routes,
  useLocation,
  Route,
} from "react-router-dom";
import Footer from './screens/Footer/Footer'
import HomePage from './screens/HomePage/HomePage'
import TermsAndConditions from './screens/Menu/TermsAndConditions'
import PrivacyPolicy from './screens/Menu/PrivacyPolicy'
import { CartContext } from './context/Cart';
import Categories from './screens/Categories/Categories';
import SubCategories from './screens/Categories/SubCategories';
import ProductDetails from './screens/ProductDetails/ProductDetails';
import UseCookie from './utils/cookies/useCookie';
import Colors from './utils/colors/Colors';
import {LocationContext} from './context/Location'
import {ThemeContext} from './context/Theme'
import { addItemsToCart,removeItemsToCart } from "./apollo/mutations";
import { ViewCart } from "./apollo/queries";
import { useMutation, gql, useQuery, useLazyQuery, useApolloClient } from "@apollo/client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContactUs from './screens/Menu/ContactUs';
import SearchProducts from './screens/HomePage/SearchProducts';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { getLocationPermission, getCurrentLocationCoordinates ,getLocationDetails,} from './utils/LocationServices/LocationServices'
import Loader from './ui-components/Loader/Loader';
import LocationAccessModal from './screens/Welcome/LocationAccessModal';
import RefundAndReturnPolicy from './screens/Menu/RefundAndReturnPolicy';
import ShippingPolicy from './screens/Menu/ShippingPolicy';
import OrderTransactionStatus from './screens/PaymentTransactions/OrderTransactionStatus';
import OrderDetails from './screens/Menu/OrderDetails';
import PageNotFound from './screens/PageNotFound/PageNotFound';
import { QueryAllCustomerTypes, QueryCustomerDetails } from "./apollo/queries";
import DetectDeviceTypeRedirect from './screens/DetectDeviceType/DetectDeviceTypeRedirect';


const addItemToCart = gql`
${addItemsToCart}
`;

const removeItemFromCart = gql`
${removeItemsToCart}
`;

const VIEWCART = gql`
${ViewCart}
`;

const getAllCustomerTypes = gql`
  ${QueryAllCustomerTypes}
`;

const getCustomerDetails = gql`
  ${QueryCustomerDetails}
`;

export default function App() {
  const {t} = useTranslation();
  const client = useApolloClient();

  // var [loginInfo, setLoginInfo] = useState(null)
  var [searchLocation, setSearchLocation] = useState(false)
  var [cartItems, setCartItems] = useState({});
  var [loading, setLoading] = useState(false);

  // const routerLocation = useLocation()
  // // console.log("🚀 ~ App ~ routerLocation:", routerLocation)
  var [appIsReady, setAppIsReady] = useState(false)
  const [location, setLocation] = useState(null)
  // const [showLocationModal, setShowLocationModal] = useState(false);
  var [appColorTheme, setAppColorTheme] = useState()
  var [loadingCartDetails, setLoadingCartDetails] = useState(false)
  const [networkStatus, setNetworkStatus] = useState(window.navigator.onLine)
  const systemThemeMode = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)?'dark':'light';
  // // console.log("🚀 ~ App ~ systemThemeMode:", systemThemeMode)
  const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme]

//   const { data:cartDetails, loading:loadingCartDetails, error,fetchMore:refetchCartData } = useQuery(VIEWCART,{ variables:{
//     // "longitude": "77.59104670126453",
//     // "latitude": "13.04269368886535",
//     "longitude": ""+(location?.longitude?location?.longitude:"77.59104670126453"),
//     "latitude": ""+(location?.latitude?location?.latitude:"13.04269368886535"),
//   },
//   fetchPolicy: "no-cache" 
// })

// const [refetchCartData, { data: cartDetails, loading: loadingCartDetails, error }] = useLazyQuery(VIEWCART, {
//   fetchPolicy: 'network-only',
//   onCompleted: (fetchMoreResult) => {
//     //console.log("🚀 ~ App ~ fetchMoreResult:", fetchMoreResult);
//     if (fetchMoreResult?.queryCart) {
//       setCartData(fetchMoreResult.queryCart.cart, null, cartItems);
//     }
//   },
//   onError: (error) => {
//     console.error("🚀 ~ App ~ error:", error);
//   }
// });

// React.useEffect(() => {
//   if (!loadingCartDetails && cartDetails) {
//     setCartData(cartDetails?.queryCart?.cart,null,cartItems)
//    //console.log("🚀 ~ ************** callCartData ~ cartDetails?.queryCart?.cart:", cartDetails)

//   }
// },[cartDetails, loadingCartDetails])

  const [ADDITEMTOCART] = useMutation(addItemToCart, { onCompleted, onError });
  const [REMOVEITEMFROMCART] = useMutation(removeItemFromCart, { removeItemCompleted, removeItemError });

  async function onCompleted({ login }) {
    // //console.log("🚀 ~ Index onCompleted ~ login:", login)
  }
  async function removeItemCompleted({ login }) {
    // //console.log("🚀 ~ Index removeItemCompleted onCompleted ~ login:", login)
  }

  function onError(errors) {
    // //console.log("🚀 ~ onError ~ errors: cart", errors)
  }

  function removeItemError(errors) {
    // //console.log("🚀 ~ removeItemError ~ errors:", errors)
  }

  const { getCustomCookie, setCustomCookie, clearAllCookies } = UseCookie();


  useEffect(()=>{
    const loadAppData = async () => {
      await checkLoginInfo()
      await checkNetworkStatus()
      await checkThemeMode()
      await getAnStoreLocationInfo()
      // await checkOrderStatus();
      setAppIsReady(true)
    }
    loadAppData()
    return () => {}
  },[])

  const checkLoginInfo = async ()=>{
    let loginInfo = await getCustomCookie("loginInfo");
    console.log("🚀 ~ checkLoginInfo ~ loginInfo:", loginInfo)
    if (!loginInfo || Object.keys(loginInfo)?.length == 0) {
      let cartItemsValue = {};
      setCartItems(cartItemsValue);
      await clearAllCookies()
    }
    
    if (loginInfo && Object.keys(loginInfo)?.length != 0) {
      // await fetchAllCustomerTypes(loginInfo) //to get updated customer types - every time when user opens the app
      await fetchCustomerDetails(loginInfo) //to get updated customer details | customer type
    }
  }

  const fetchAllCustomerTypes = async(loginInfo)=>{
    try{
      const { data } = await client.query({
        query: getAllCustomerTypes,
        variables:{},
        fetchPolicy: 'no-cache',
      });
      await setCustomCookie('allCustomerTypes',JSON.stringify(data?.getAllCustomerTypes))
      let generalCustomerType = await fetchGeneralCustomerTypeIfPremium(data?.getAllCustomerTypes,loginInfo)
      await setCustomCookie('generalCustomerType',JSON.stringify(generalCustomerType))
    }
    catch(error){
      toast.error("Error to fetch all customer types")
    }
  }

  //This works only for a premuim customer and return general customer type
  const fetchGeneralCustomerTypeIfPremium = async(allCustomerTypes,loginInfo)=>{
    let index = allCustomerTypes?.findIndex(data=>data?.default == true) //default true indicates that customer is a general customer , not premium customer
    if(loginInfo?.customerType != allCustomerTypes[index]?.customerType){
      return allCustomerTypes[index]?.customerType
    }
    //this is to display general customer price, along with premium price
  }

  const fetchCustomerDetails = async(loginInfo)=>{
    try{
      const { data } = await client.query({
        query: getCustomerDetails,
        variables:{},
        fetchPolicy: 'no-cache',
      });
      await setCustomCookie('loginInfo',JSON.stringify({...data?.queryCustomer,jwtToken:loginInfo?.jwtToken}))
      await fetchAllCustomerTypes({...data?.queryCustomer,jwtToken:loginInfo?.jwtToken}) //to get updated customer types - every time when user opens the app
    }
    catch(error){
      toast?.error("Error to fetch customer details")
    }
  }
  
  const checkNetworkStatus = ()=>{
    const handleOnlineStatus = () => setNetworkStatus(true);
    const handleOfflineStatus = () => setNetworkStatus(false);
    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOfflineStatus);
  }

  const checkThemeMode = async ()=>{
    let theme = await getCustomCookie('theme');
    // //console.log("🚀 ~ 122 getAnStoreLocationInfo ~ theme:", theme)
    setAppColorTheme(theme);
  }

  const getAnStoreLocationInfo = async()=>{
    try {
      let locationInfo = await getCustomCookie('locationInfo')
      if (Object.keys(locationInfo).length!=0) {
        setLocation(locationInfo)
      }
    } catch (err) {
      //console.log(err)
    }
  }

  useEffect(() => {
    // verifyAccount();
    // handleGetLocation();
  }, [])

  // const verifyAccount = async()=>{
  //   loginInfo = await getCustomCookie("loginInfo")
  //   setLoginInfo(loginInfo)
  // }

  useLayoutEffect(() => {
    assignThemeMode(appColorTheme)
    //console.log("appColorTheme",appColorTheme)
  }, [appColorTheme])

    //save location details to local storage
    useEffect(() => {
      if (!location) return
  
      const saveLocation = async () => {
        await setCustomCookie('locationInfo', JSON.stringify(location))
      }
  
      saveLocation()
    }, [location])

  const assignThemeMode = useCallback(async (appColorTheme)=>{
    if (!appColorTheme) {
      //console.log("app theme", false)
      let theme = 'systemDefault'
      setAppColorTheme(theme)
      
      // console.log("🚀 ~ assignThemeMode ~ theme:", theme)
      await saveAppColorTheme(theme) // systemThemeMode
    }
    else{
      //console.log("app theme", true, appColorTheme)
      await saveAppColorTheme(appColorTheme)
    }
  },[])

  const saveAppColorTheme = useCallback(async (theme) => {
    // //console.log("🚀 ~ saveAppColorTheme ~ JSON.stringify(theme):", JSON.stringify(theme))
    await setCustomCookie('theme', JSON.stringify(theme))
    try{
      if(theme = 'dark'){
        document.documentElement.classList.add('dark');
      }
      else{
        document.documentElement.classList.remove('dark');
      }
      // if(theme = 'systemDefault'){
      //   document.documentElement.classList.remove('dark', 'light');
      // }
      // else{
      //   document.documentElement.classList.remove('dark', 'light');
      //   document.documentElement.classList.add(theme);
      // }
    }
    catch(error){
      toast.error(error)
    }
  },[])

  //Manage Cart Data

  const addToCart = useCallback(async(item,index,cartItems, isLocationServicable,loginInfo, locationInfo) => {
   //console.log("🚀 ~ addToCart ~ loginInfo:", loginInfo)
    // let isLocationServicable = await getCustomCookie('isLocationServicable')
    // console.log("🚀 ~ addToCart ~ isLocationServicable:", isLocationServicable)
    if((loginInfo && Object.keys(loginInfo)?.length !=0)){
      if(isLocationServicable){
        //console.log("addTocart")
        // if(!loginInfo){
        //   loginInfo = await getCustomCookie('loginInfo')
        //  //console.log("🚀 ~ addToCart ~ loginInfo:", loginInfo)
        // }
        // Vibration.vibrate(50)
        //get item qunatity and increment by 1,assign itemId, productId avail
        item = {...item,quantity:Number.isInteger(item?.quantity)?item?.quantity+1:1,itemId:item?.itemId?item?.itemId:item?.productId,productId:item?.itemId?item?.itemId:item?.productId}
        // // console.log("🚀 166 ~ addToCart ~ item:", item)
        let cartDataObject = JSON.parse(JSON.stringify({...cartItems}))
        //update item object with latest quantity and id's
        cartDataObject = {...cartDataObject,cartobj : {...cartDataObject?.cartobj,[item['itemId']]:item}}
        // cartDataObject.cartobj[item['itemId']]  = item
        //if index is not passed as argument then find the index of itemid and update the array
        if(isNaN(index)){
          cartDataObject.items = cartDataObject?.items?cartDataObject?.items:[];
          let index = cartDataObject['items']?.findIndex(dataItem => (dataItem.itemId ?? dataItem.productId) == (item.itemId ?? item.productId))
          if(index!=-1){
            cartDataObject.items[index] = item
          }
          else{
            // cartDataObject['items'] = [...cartDataObject['items'],item]
            cartDataObject['items'] = cartDataObject['items'].concat(item)
          }
        }
        else{
          cartDataObject.items[index] = item
        }
        setCartItems(cartDataObject)
       //console.log("🚀 187 ~ addToCart ~ cartDataObject:", cartDataObject,loginInfo)
        if((loginInfo && Object.keys(loginInfo)?.length !=0)){
          await updateCartItems(item,loginInfo,locationInfo)
        }
      }
      else{
        toast.error("Can't Add, Service not available for the selected location. Please change the address on the home page")
      }
    }
    else{
      toast.error("Please login!",{
        position: "top-right",
        autoClose: 2000,
        theme: systemThemeMode,})
    }
  },[]);
 
  
  
  const removeFromCart = useCallback(async(item,index,cartItems, isLocationServicable,loginInfo, locationInfo) => {
   //console.log("🚀 ~ removeFromCart ~ loginInfo:", loginInfo)
    // let isLocationServicable = await getCustomCookie('isLocationServicable')
    // console.log("🚀 ~ removeFromCart ~ isLocationServicable:", isLocationServicable)
    if((loginInfo && Object.keys(loginInfo)?.length !=0)){
      if(isLocationServicable){
        // if(!loginInfo){
        //   loginInfo = await getCustomCookie('loginInfo')
        //  //console.log("🚀 ~ removeFromCart ~ loginInfo:", loginInfo)
        // }
        // Vibration.vibrate(50)
        if(item?.quantity == 1){
          let updatedCartItems = JSON.parse(JSON.stringify({...cartItems}))
          delete updatedCartItems['cartobj'][item?.itemId ?? item?.productId]
          if(isNaN(index)){
            let indexValue = cartItems['items'].findIndex(dataItem => (dataItem.itemId ?? dataItem.productId) == (item.itemId ?? item.productId))
            if(indexValue!=-1){index = indexValue}
          }
          updatedCartItems['items'] = await deleteElementByIndex(updatedCartItems['items'],index)
          setCartItems(updatedCartItems);
          setCartData(updatedCartItems,null,updatedCartItems)
          if((loginInfo && Object.keys(loginInfo)?.length !=0)){
            await deleteItemFromCart(item,loginInfo, locationInfo)
          }
        }
        else{
          item = {...item,quantity:item?.quantity-1,itemId:item?.itemId?item?.itemId:item?.productId,productId:item?.itemId?item?.itemId:item?.productId}
          let cartDataObject = JSON.parse(JSON.stringify({...cartItems}))
          cartDataObject = {...cartDataObject,cartobj : {...cartDataObject?.cartobj,[item['itemId']]:item}}
          if(isNaN(index)){
            let index = cartDataObject['items'].findIndex(dataItem => (dataItem.itemId ?? dataItem.productId) == (item.itemId ?? item.productId))
            if(index!=-1){
              cartDataObject.items[index] = item
            }
            else{
              cartDataObject['items'] = [...cartDataObject['items'],item]
            }
          }
          else{
            cartDataObject.items[index] = item
          }
          setCartItems(cartDataObject)
          // await setCartData(cartDataObject) --------- bug to fix
          if((loginInfo && Object.keys(loginInfo)?.length !=0)){
            await updateCartItems(item,loginInfo, locationInfo)
          }
        }
      }
      else{
        toast.error("Can't Remove, Service not available for the selected location. Please change the address on the home page",{
          position: "top-right",
          autoClose: 5000,
          theme: systemThemeMode,})
      }
    }
    else{
      toast.error("Please login!",{
        position: "top-right",
        autoClose: 2000,
        theme: systemThemeMode,})
    }
  },[])
  
  const  deleteElementByIndex = (arr, index)=>{
    if (index < 0 || index >= arr.length) {
      throw new Error('Index out of bounds');
    }
    for (let i = index; i < arr.length - 1; i++) {
        arr[i] = arr[i + 1];
      }
      if(arr?.length>0){
        arr.length--; // Reduce the length of the array by 1 to remove the last element
      }
    return arr.slice(0, arr.length); // Return a new array without the last element
  }

  const updateCartItems = async(item,loginInfo, locationInfo) => {
    let requestBody = {
      "cartAddInputs": {
        "itemInputs": [
              {
                "productId": item?.itemId?item?.itemId:item?.productId,
                "quantity": item?.quantity,
                "skuId": item?.skuId
              }
            ]
          }
        }
       //console.log("🚀 ~ updateCartItems ~ requestBody:", requestBody)
    try{
      let response = await ADDITEMTOCART({ variables:requestBody})
     //console.log("🚀 188 ~ updateCartItems ~ response:", response)
      // loginInfo = await getCustomCookie('loginInfo')
      // await callCartData(cartItems,loginInfo,locationInfo)
      if(response?.cartDetails) {
      }
    }catch(error){
     //console.log("🚀 ~ updateCartItems ~ error:", error)
      // toast.error(e?.message)
    }
  }

  const deleteItemFromCart = async(item,loginInfo,locationInfo)=>{

    let requestBody = {"item":{
      "productId": item?.itemId?item?.itemId:item?.productId,
      "quantity": item?.quantity,
      "skuId": item?.skuId
    }}
  try{
    let response = await REMOVEITEMFROMCART({ variables:requestBody})
    // loginInfo = await getCustomCookie('loginInfo')
    // await callCartData(cartItems,loginInfo,locationInfo)
    if(response?.cartDetails){
    }
  }catch(error){
   //console.log("🚀 ~ deleteItemFromCart ~ error:", error)
    // toast.error(e?.message)
  }
  }

  const callCartData = useCallback(async (cartItems,loginInfo, locationInfo) => {
    //console.log("🚀 ~ callCartData ~ cartItems,loginInfo, locationInfo:", cartItems,loginInfo, locationInfo)
    // let locationInfo = await getCustomCookie('locationInfo')
   //console.log("🚀 ~ callCartData ~ loginInfo:", loginInfo, locationInfo)
    if(loginInfo && Object.keys(loginInfo)?.length !=0){
      	try {
          //console.log("karthik")
          loadingCartDetails = true
          setLoadingCartDetails(loadingCartDetails)
          let requestBody= {
            "longitude": ""+((locationInfo &&locationInfo?.longitude)?locationInfo?.longitude:"77.59104670126453"),
            "latitude": ""+((locationInfo && locationInfo?.latitude)?locationInfo?.latitude:"13.04269368886535"),
            }
          //console.log("🚀 ~ callCartData ~ requestBody:", requestBody)
          // await refetchCartData({variables: requestBody,});  

          const { data } = await client.query({
            query: VIEWCART,
            variables:requestBody,
            fetchPolicy: 'network-only',
          });
          if (data?.queryCart) {
            setCartData(data.queryCart.cart, null, cartItems);
          }
          loadingCartDetails = false
          setLoadingCartDetails(loadingCartDetails)
          //console.log("Cloud")
        } catch (error) {
          //console.log("🚀 ~ callCartData ~ error:", error)  
        }
    	}
    	else{
      	await setCartData(null,false,cartItems)
    	}
  	},[]);

    const setCartData = useCallback( async (cartDetails, dataEmpty,cartItems) => {
      if (cartDetails) {
        let cartData = cartDetails;
        if(cartDetails?.items?.length>0){
          let itemsById = cartData?.items?.reduce((acc, item) => {
            acc[item?.itemId ?? item?.productId] = item;
            return acc;
          }, {});
          cartData = { ...cartData, cartobj: itemsById };
        }
        else{
          cartData = { ...cartDetails,items: [], cartobj: {} };
        }
        setCartItems(cartData);
      } else {
        let cartData = {};
        if (cartItems && Object.keys(cartItems).length > 0 && !dataEmpty) {
          cartData = { ...cartItems };
          setCartItems(cartData);
        }
        else if(cartItems && Object.keys(cartItems).length > 0 && dataEmpty){
          cartData = { ...cartItems,items: [], cartobj: {} };
          setCartItems(cartData);
        } else {
          cartData = { items: [], cartobj: {} };
          setCartItems(cartData);
        }
      }
    },[]);

  const handleGetLocation = async () => {
    try{
      loading = true
      setLoading(loading)
      let permissions = await getLocationPermission()
      // console.log("🚀 ~ handleGetLocation ~ permissions:", permissions)
      if(permissions){
        let coordinates = await getCurrentLocationCoordinates()
        if(coordinates){
          loading = false
          setLoading(loading)
          let locationDetails = await getLocationDetails(coordinates)
          // console.log("🚀 ~ handleGetLocation ~ locationDetails:", locationDetails)
          setLocation(locationDetails)
        }
      }
    }
    catch(error){
      toast.error(error)
    }
  }


  // const handleClickOutside = (event) => {if (modalRef.current && !modalRef.current.contains(event.target)) {closeModal();}};

  // const openModal = () => {
  //     setShowLocationModal(true);
  //     document.addEventListener('mousedown', handleClickOutside);
  // };

  // const closeModal = () => {
  //  //console.log("Welcome puuja")
  //     setShowLocationModal(false);
  //     document.removeEventListener('mousedown', handleClickOutside);
  // };

  if (appIsReady) {
  return (
    <>
      <LocationContext.Provider value={{ location, setLocation }}>
        <ThemeContext.Provider value={{ systemThemeMode,appColorTheme, setAppColorTheme }}>  
          <CartContext.Provider value={{ cartItems, loadingCartDetails,setCartItems, addToCart,removeFromCart, setCartData, callCartData }}>
            <Loader loading={loading} />
            <div className='body'>
            <ToastContainer 
            theme={appColorTheme=='systemDefault'?systemThemeMode:appColorTheme} />
              <BrowserRouter>
                {/* <LocationAccessModal location={location} handleGetLocation={handleGetLocation} setSearchLocation={setSearchLocation} /> */}
                <NavBar handleGetLocation={handleGetLocation} searchLocation={searchLocation} setSearchLocation={setSearchLocation}/>
                  <div style={{backgroundColor:Colors.colorTheme[colors?.homePageBg]}} className={`pt-32 xs:pt-32 sm:pt-32 md:pt-20 lg:pt-20 xl:pt-20 xxl:pt-20 pb-2  bg-${colors?.homePageBg} shadow-slate-500 dark:bg-${colors?.homePageBg} min-h-screen`}>
                    <div className='mx-2p sm:mx-10p md:mx-10p lg:mx-10p xl:mx-10p'>
                        <Routes>
                            <Route path="/" element={<HomePage/>} />
                            <Route path="/apps" element={<DetectDeviceTypeRedirect/>} />
                            <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>} />
                            <Route path="/TermsAndConditions" element={<TermsAndConditions/>} />
                            <Route path="/RefundAndReturnPolicy" element={<RefundAndReturnPolicy/>} />
                            <Route path="/ShippingPolicy" element={<ShippingPolicy/>} />
                            <Route path="/ContactUs" element={<ContactUs/>} />
                            <Route path="/SearchProducts" element={<SearchProducts/>} />
                            <Route path="/Categories" element={<Categories/>} />
                            <Route path="/SubCategories/:id" element={<SubCategories/>} />
                            <Route path="/productDetails/:id" element={<ProductDetails/>} />
                            <Route path="/Order/Payment-Status/:id" element={<OrderTransactionStatus/>} />
                            <Route path="/Order/OrderDetails/:id" element={<OrderDetails/>} />
                            <Route path="*" element={<PageNotFound />} />
                        </Routes>
                    </div>
                  </div>
                { <Footer/>}
              </BrowserRouter>
            </div>
          </CartContext.Provider>
        </ThemeContext.Provider>
      </LocationContext.Provider>
    </>
    
  )
  }
  else{
    return <></>
  }
}
